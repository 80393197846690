.swing{
    /* animation: swing ease-in-out 0.5s infinite alternate; */
  
 
    box-shadow:1px rgba(0,0,0,0.5);
}


.swing:after{
    content: '';
     
    width: 20px; height: 20px;  
    border: 1px solid #999;
    top: -10px; left: 10%;
    z-index: 0;
    border-bottom: none;
    border-right: none;
    /* transform: rotate(3deg); */
}
/* nail */
.swing:before{
    content: '';
    position: absolute;
    width: 5px; height: 5px;
    top: -14px;left: 11%;
    z-index: 5;
    border-radius: 50% 50%;

}
 
/* @keyframes swing {
    0% { transform: rotate(3deg); }
    100% { transform: rotate(-3deg); }
} */


/* @media (max-width: 1100px) {
 .swing2 {
        animation: none;
      
     
        box-shadow:1px rgba(0,0,0,0.5);
    }
} */