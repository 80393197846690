.carousel {
    position: relative;
  }
  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  .carousel-inner > .item {
    position: relative;
    display: none;
    -webkit-transition: .6s ease-in-out left;
         -o-transition: .6s ease-in-out left;
            transition: .6s ease-in-out left;
  }
  .carousel-inner > .item > img,
  .carousel-inner > .item > a > img {
    line-height: 1;
  }
  @media all and (transform-3d), (-webkit-transform-3d) {
    .carousel-inner > .item {
      -webkit-transition: -webkit-transform .6s ease-in-out;
           -o-transition:      -o-transform .6s ease-in-out;
              transition:         transform .6s ease-in-out;
  
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      -webkit-perspective: 1000;
              perspective: 1000;
    }
    .carousel-inner > .item.next,
    .carousel-inner > .item.active.right {
      left: 0;
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0);
    }
    .carousel-inner > .item.prev,
    .carousel-inner > .item.active.left {
      left: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0);
    }
    .carousel-inner > .item.next.left,
    .carousel-inner > .item.prev.right,
    .carousel-inner > .item.active {
      left: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  .carousel-inner > .active,
  .carousel-inner > .next,
  .carousel-inner > .prev {
    display: block;
  }
  .carousel-inner > .active {
    left: 0;
  }
  .carousel-inner > .next,
  .carousel-inner > .prev {
    position: absolute;
    top: 0;
    width: 100%;
  }
  .carousel-inner > .next {
    left: 100%;
  }
  .carousel-inner > .prev {
    left: -100%;
  }
  .carousel-inner > .next.left,
  .carousel-inner > .prev.right {
    left: 0;
  }
  .carousel-inner > .active.left {
    left: -100%;
  }
  .carousel-inner > .active.right {
    left: 100%;
  }
      
      
  .carousel-control.rightar {
    right: 0;
    left: auto;
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, .0001) 0%, rgba(0, 0, 0, .5) 100%);
    background-image:      -o-linear-gradient(left, rgba(0, 0, 0, .0001) 0%, rgba(0, 0, 0, .5) 100%);
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, .0001)), to(rgba(0, 0, 0, .5)));
    background-image:    none;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
    background-repeat: repeat-x;
  }
   /* carousel */
  .media-carousel 
  {
    margin-bottom: 0;
    padding: 0 40px 30px 40px;
    margin-top: 30px;
  }
  /* Previous button  */
  .media-carousel .carousel-control.left 
  {
    left: -12px;
    background-image: none;
    background: none repeat scroll 0 0 #a2ab58;
    border: 4px solid #FFFFFF;
    border-radius: 23px 23px 23px 23px;
    height: 40px;
    width : 40px;
    margin-top: 50px
  }
  
  .media-carousel .carousel-control.leftar 
  {
       left: 0px !important;
      background-image: none;
      background: none repeat scroll 0 0 transparent;
      border: 4px solid transparent;
      height: 60px;
      width: 60px;
      margin-top: 80px;
  }
  
  /* Next button  */
  .media-carousel .carousel-control.right 
  {
    right: -12px !important;
    background-image: none;
    background: none repeat scroll 0 0 transparent;
    border: 4px solid transparent;
    height: 120px;
    width : 120px;
    margin-top: 80px;
  }
  
  
  .media-carousel .carousel-control.rightar 
  {
      right: 0px !important;
      background-image: none;
      background: none repeat scroll 0 0 transparent;
      border: 4px solid transparent;
      height: 60px;
      width: 60px;
      margin-top: 80px;
  }
  
  
  /* Changes the position of the indicators */
  .media-carousel .carousel-indicators 
  {
    right: 50%;
    top: auto;
    bottom: 0px;
    margin-right: -19px;
  }
  /* Changes the colour of the indicators */
  .media-carousel .carousel-indicators li 
  {
    background: #c0c0c0;
  }
  .media-carousel .carousel-indicators .active 
  {
    background: #333333;
  }
  .media-carousel img
  {
    width: 100%;
    height: 100%;
  }
  
  
  .testimonial{
      background-color: #EBEBEB;
      color:#000;
  }
  
  .testimonial-heading{
      color:#000;
      text-align:center;
      text-decoration:underline;
  }
  
  
  
  
  
  /* video player code here     */
  
          
  
  .gal-container{
      padding: 12px;
  }
  .gal-item{
      overflow: hidden;
      padding: 3px;
  }
  .gal-item .box{
      height: 350px;
      overflow: hidden;
  }
  .box img{
      height: 100%;
      width: 100%;
      object-fit:cover;
      -o-object-fit:cover;
  }
  .gal-item a:focus{
      outline: none;
  }
  .gal-item a:after{
      content:"\e003";
      font-family: 'Glyphicons Halflings';
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.75);
      position: absolute;
      right: 3px;
      left: 3px;
      top: 3px;
      bottom: 3px;
      text-align: center;
      line-height: 350px;
      font-size: 30px;
      color: #fff;
      -webkit-transition: all 0.5s ease-in-out 0s;
      -moz-transition: all 0.5s ease-in-out 0s;
      transition: all 0.5s ease-in-out 0s;
  }
  .gal-item a:hover:after{
      opacity: 1;
  }
  .modal-open .gal-container .modal{
      background-color: rgba(0,0,0,0.4);
  }
  .modal-open .gal-item .modal-body{
      padding: 0px;
  }
  .modal-open .gal-item button.close{
      position: absolute;
      width: 30px;
      height: 30px;
      background-color: #000;
      opacity: 1;
      color: #fff;
      z-index: 999;
      right: -12px;
      top: -12px;
      border-radius: 50%;
      font-size: 15px;
      border: 2px solid #fff;
      line-height: 25px;
      -webkit-box-shadow: 0 0 1px 1px rgba(0,0,0,0.35);
      box-shadow: 0 0 1px 1px rgba(0,0,0,0.35);
  }
  .modal-open .gal-item button.close:focus{
      outline: none;
  }
  .modal-open .gal-item button.close span{
      position: relative;
      top: -3px;
      font-weight: lighter;
      text-shadow:none;
  }
  .gal-container .modal-dialogue{
      width: 80%;
  }
  .gal-container .description{
      position: relative;
      height: 40px;
      top: -40px;
      padding: 10px 25px;
      background-color: rgba(0,0,0,0.5);
      color: #fff;
      text-align: left;
  }
  .gal-container .description h4{
      margin:0px;
      font-size: 15px;
      font-weight: 300;
      line-height: 20px;
  }
  .gal-container .modal.fade .modal-dialog {
      -webkit-transform: scale(0.1);
      -moz-transform: scale(0.1);
      -ms-transform: scale(0.1);
      transform: scale(0.1);
      top: 100px;
      opacity: 0;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
  }
  
  .gal-container .modal.fade.in .modal-dialog {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      -webkit-transform: translate3d(0, -100px, 0);
      transform: translate3d(0, -100px, 0);
      opacity: 1;
  }
  @media (min-width: 768px) {
  .gal-container .modal-dialog {
      width: 55%;
      margin: 50 auto;
  }
  }
  @media (max-width: 768px) {
      .gal-container .modal-content{
          height:250px;
      }
  }
  /* Footer Style */
  i.red{
      color:#BC0213;
  }
  .gal-container{
      padding-top :75px;
      padding-bottom:75px;
  }
  footer{
      font-family: 'Quicksand', sans-serif;
  }
  footer a,footer a:hover{
      color: #88C425;
  }
  
  
  
  
  /* video player code end */
  
  
  .test-vid{
      width:100%;
      height:auto;
  }
  
  
  .panel-footer-txt > p{
      letter-spacing:10px;
      color:#fff;
      padding-top:5px;
      
  }
  .panel-footer{
      background-color:#1480D8!important;
  }
  .panel-footer-txt > p:hover{
      color:#a2ab58;
  }
  
  
  .checked {
      color: orange;
  }