/* body {
  margin: 0;

  font-family: "Poppins";

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
div,
section,
h1,
h2,
h3,
h4,
h5,
h6,
span,
input,
label,
textarea {
  font-family: "Poppins";
} */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.section-team {
  /* font-family: "Poppins"; */
  padding: 80px 0;
}
.panel-body{
  font-size: 14px;
}
.section-team .header-section {
  margin-bottom: 50px;
}

.section-team .header-section .small-title {
  margin-bottom: 25px;
  font-size: 16px;
  font-weight: 500;
  color: #3e64ff;
}

.section-team .header-section .title {
  font-weight: 700;
  font-size: 45px;
}

.section-team .single-person {
  margin-top: 30px;
  padding: 30px;
  background-color: #f6f9ff;
  border-radius: 5px;
}

.section-team .single-person:hover {
  background: linear-gradient(to right, #016cec, #00b5f7);
}

.section-team .single-person .person-image {
  position: relative;
  margin-bottom: 50px;
  border-radius: 50%;
  border: 4px dashed transparent;
  transition: padding 0.3s;
}

.section-team .single-person:hover .person-image {
  padding: 12px;
  border: 4px dashed #fff;
}

.section-team .single-person .person-image img {
  width: 100%;
  border-radius: 50%;
}

.section-team .single-person .person-image .icon {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background: linear-gradient(to right, #016cec, #00b5f7);
  color: #fff;
  border-radius: 50%;
  font-size: 24px;
}

.section-team .single-person:hover .person-image .icon {
  background: none;
  background-color: #fff;
  color: #016cec;
}

.section-team .single-person .person-info .full-name {
  margin-bottom: 10px;
  font-size: 28px;
  font-weight: 700;
}

.section-team .single-person .person-info .speciality {
  text-transform: uppercase;
  font-size: 14px;
  color: #016cec;
}

.section-team .single-person:hover .full-name,
.section-team .single-person:hover .speciality {
  color: #fff;
}
#datepick{
outline:none !important;
border:none !important;
width: 100%;
background-color: none !important;
background: none;
}


/* 
body {
  margin: 0;
  padding: 0;
  background-color: #FAFAFC;
  font-family: "europa", sans-serif;
} */

.title {
  background-color: white;
  padding: 15px;
  margin: 0;
  text-align: center;
  color: #286DA8;
}


.add-text-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 3vh;
  border-bottom: 1px solid lightgrey;
  box-shadow: 0 2px 4px 0 rgba(73,73,73,0.1);
  background-color: white;
  font-size: 16px;
}

.error-messages {
  color: red;
}

.number-container {
  margin-bottom: 15px;
}

label {
  margin-right: 8px;
  margin-left: 15px;
}

.number-box {
  border: none;
  border-bottom: 1px solid lightgrey;
  width: 60px;
  text-align: center;
  outline: none;
  font-size: 16px;
}

.number-box:hover {
  border-bottom: 1.5px solid #286DA8;
  transition: border-bottom 0.2s ease-in;
}

button {
  font-size: 16px;
  background-color: #286DA8;
  color: white;
  border: 1px solid #286DA8;
  border-radius: 4px;
  padding: 15px;
  cursor: pointer;
}

button:hover {
  background-color: #4591d3;
  border: 1px solid #4591d3;
  transition: background-color 0.5s
}
/* 
.display-text-group{

  min-height: 25vh;
  padding: 10px;
  float: left;
  font-weight: lighter !important;
  align-items: left;
  justify-content: center;
  border: 1px solid lightgrey;
  margin: 5px;
  border-radius: 4px;
  box-shadow: 0 8px 14px 0 white;
  background-color: white;
} */
/* 
.display-text-group:hover {
  box-shadow: 0 18px 27px 0 rgba(0,0,0,.1);
} */

.text-display-group:after {
  content: "";
  display: block;
  clear: both;
}



.read-more-button {
  
  margin-left: 3px;
  color: #286DA8;
  cursor: pointer;
}

.read-more-button:hover {
  color: #4591d3;
}

.form-control{
  height: 60px;
}