.pagescroll{
    color:black;
    
      font-size:18px;
       font-weight:lighter

       
}

.pagescroll:hover{
    color:gray;
     border-bottom:5px solid green;
      font-size:20px;
     
}